<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Configs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        :items="environments"
        v-model="environmentsSelect"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Env"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-icon large class="mr-2" color="primary" @click="detail">add</v-icon>
      <v-icon large class="mr-2" color="primary" @click="exportJson"
        >save_alt</v-icon
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :sort-by="['id']"
      :sort-desc="[true]"
      hide-default-footer
      :items="reportsData.all"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <div style="width:100px">
          <v-icon class="mr-2" @click="detail(item)">edit</v-icon>
          <v-icon class="mr-2" @click="confirmDelete(item)">delete</v-icon>
        </div>
      </template>
    </v-data-table>

    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1600px">
        <v-card>
          <v-card-title>
            <span class="headline">Config Detail</span>
            <v-spacer></v-spacer>
            <v-btn color="red " text @click="dialog = false">Close</v-btn>

            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="currentConfig.key"
                  label="Key"
                  class="mb-2"
                  :disabled="currentConfig.id != null"
                  single-line
                ></v-text-field>
                <v-text-field
                  v-model="environmentsSelect.value"
                  label="Env"
                  class="mb-4"
                  disabled
                  single-line
                ></v-text-field>
                <v-btn color="green darken-1" text @click="beautify"
                  >Beautify</v-btn
                >

                <v-alert
                  v-if="jsonstr && jsonerror"
                  border="left"
                  type="error"
                  >{{ jsonerror }}</v-alert
                >
                <div class="text-success" v-if="!jsonerror">Valid JSON ✔</div>
                <v-textarea
                  outlined
                  name="input-7-1"
                  label="Default style"
                  auto-grow
                  ref="jsonText"
                  counter
                  v-model="jsonstr"
                  @keyup="prettyFormat"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Confirm Config Delete</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-alert border="left" type="error"
                  >{{ currentConfig.id }} - {{ currentConfig.env }} -
                  {{ currentConfig.key }} silmek istediğinizden emin
                  misiniz!</v-alert
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDelete = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItem">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      chatDialog: false,
      currentConfig: {},
      environmentsSelect: { value: "Prod", key: "prod" },
      environments: [
        { value: "Prod", key: "prod" },
        { value: "Test", key: "test" },
      ],
      jsonstr: "",
      jsonerror: "",
      currentPage: 1,
      reportsData: {},
      clicked: false,
      detailClicked: true,
      headers: [
        { text: "id", value: "id" },
        { text: "Env", value: "env" },
        { text: "Key", value: "key" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },

  methods: {
    prettyFormat: function() {
      // reset error
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.jsonerror = "";
      let jsonValue = "";
      try {
        // try to parse
        jsonValue = JSON.parse(this.jsonstr);
      } catch (e) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.jsonerror = JSON.stringify(e.message);
        // var textarea = this.$refs.jsonText
        if (this.jsonerror.indexOf("position") > -1) {
          // highlight error position
          var positionStr = this.jsonerror.lastIndexOf("position") + 8;
          var posi = parseInt(
            this.jsonerror.substr(positionStr, this.jsonerror.lastIndexOf('"'))
          );
          if (posi >= 0) {
            // textarea.setSelectionRange(posi,posi+1)
          }
        }
        return "";
      }
      return JSON.stringify(jsonValue, null, 2);
    },
    async initialize() {
      localStorage.setItem(
        "environmentsSelect",
        JSON.stringify(this.environmentsSelect)
      );
      await this.$store.dispatch("configs/getAll", this.environmentsSelect.key);
      this.reportsData = await this.$store.state.configs;
      // eslint-disable-next-line no-console
      console.log(this.reportsData);
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async exportJson() {
      let data = await this.$store.dispatch(
        "configs/getExport",
        this.environmentsSelect.key
      );
      let dataStr = JSON.stringify(data);
      let dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
      let exportFileDefaultName = "remoteConfig.json";
      let linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      linkElement.setAttribute("download", exportFileDefaultName);
      linkElement.click();
    },
    async detail(item) {
      this.jsonerror = "";
      this.currentConfig = item;
      this.currentConfig.env = this.environmentsSelect.key;
      this.jsonstr = JSON.stringify(item.data, null, "\t");
      this.dialog = true;
    },
    beautify() {
      this.jsonstr = JSON.stringify(JSON.parse(this.jsonstr), null, "\t");
    },
    async save() {
      this.currentConfig.data = JSON.parse(this.jsonstr);
      await this.$store.dispatch("configs/postConfigs", this.currentConfig);
      this.dialog = false;
      this.initialize();
    },
    async confirmDelete(item) {
      this.currentConfig = item;
      this.dialogDelete = true;
    },
    async deleteItem() {
      await this.$store.dispatch("configs/delete", this.currentConfig.id);
      this.dialogDelete = false;
      this.initialize();
    },
  },
  async mounted() {
    let env = localStorage.getItem("environmentsSelect");
    if (env) this.environmentsSelect = JSON.parse(env);
    this.initialize();
  },
};
</script>

<style>
.v-dialog {
  background: #424242 !important;
}
</style>
